import { ConditionFact, PageType } from "@reconvert/reconvert-utils";
import { getAllPageConditions } from "../services/conditions.service";

const validateRequiredValue = (conditionData: any): { isInValid: boolean; isInputvalid: boolean } => {
  const isInValid = !conditionData.name || !conditionData.condition || !conditionData.operator;

  switch (conditionData?.fact) {
    case ConditionFact.SPECIFIC_PRODUCT:
      if (isInValid || conditionData.productIds.length === 0) {
        return { isInValid: true, isInputvalid: true };
      }

      break;
    case ConditionFact.PRODUCT_COLLECTION:
      if (isInValid || conditionData?.productCollectionIds?.length === 0) {
        return { isInValid: true, isInputvalid: true };
      }

      break;
    case ConditionFact.PRODUCT_TITLE:
    case ConditionFact.PRODUCT_VARIANT_NAME:
    case ConditionFact.PRODUCT_SKU:
      if (isInValid || !conditionData?.value) {
        return { isInValid: true, isInputvalid: true };
      }

      break;

    case ConditionFact.CUSTOMER_EMAIL:
    case ConditionFact.DISCOUNT_CODE:
      if (!conditionData.name || !conditionData.operator) {
        return { isInValid: true, isInputvalid: true };
      }

      break;

    case ConditionFact.CUSTOMER_COUNTRY:
    case ConditionFact.CUSTOMER_LANGUAGE:
    case ConditionFact.CUSTOMER_SEGMENTS:
      if (!conditionData.name || !conditionData.operator || conditionData?.items?.length === 0) {
        return { isInValid: true, isInputvalid: true };
      }

      break;
    case ConditionFact.PRODUCT_TAGS:
    case ConditionFact.PRODUCT_SALES_CHANNEL:
    case ConditionFact.CUSTOMER_TAG:
      if (isInValid || conditionData?.items?.length === 0) {
        return { isInValid: true, isInputvalid: true };
      }

      break;
    case ConditionFact.CUSTOMER_NUMBER_OF_ORDERS:
    case ConditionFact.CART_OR_ORDER_VALUE:
      if (conditionData.operator === "between" || conditionData.operator === "notBetween") {
        return {
          isInValid:
            !conditionData.name ||
            !conditionData.condition ||
            !conditionData.operator ||
            !conditionData.fromValue ||
            !conditionData.toValue,
          isInputvalid: !isNaN(conditionData.fromValue) && !isNaN(conditionData.toValue),
        };
      } else {
        return {
          isInValid: !conditionData.name || !conditionData.condition || !conditionData.operator || !conditionData.value,
          isInputvalid: !isNaN(conditionData.value),
        };
      }

    case ConditionFact.PREV_PAGE_OFFER:
      if (isInValid) {
        return { isInValid: true, isInputvalid: true };
      }

      break;

    default:
      break;
  }
  return { isInValid: false, isInputvalid: true };
};

const canSaveCondition = (conditionData: any, activePage: string | undefined): boolean => {
  switch (conditionData?.fact) {
    case ConditionFact.SPECIFIC_PRODUCT:
      return conditionData.name && conditionData.productIds.length > 0;
    case ConditionFact.PRODUCT_COLLECTION:
      return conditionData.name && conditionData?.productCollectionIds?.length > 0;
    case ConditionFact.PRODUCT_TAGS:
    case ConditionFact.CUSTOMER_COUNTRY:
    case ConditionFact.CUSTOMER_LANGUAGE:
    case ConditionFact.PRODUCT_SALES_CHANNEL:
    case ConditionFact.CUSTOMER_TAG:
    case ConditionFact.CUSTOMER_SEGMENTS:
      return conditionData.name && conditionData?.items?.length > 0;
    case ConditionFact.CUSTOMER_EMAIL:
    case ConditionFact.PRODUCT_TITLE:
    case ConditionFact.DISCOUNT_CODE:
    case ConditionFact.PRODUCT_VARIANT_NAME:
    case ConditionFact.PRODUCT_SKU:
      return conditionData.name && conditionData?.value;
    case ConditionFact.CUSTOMER_NUMBER_OF_ORDERS:
    case ConditionFact.CART_OR_ORDER_VALUE:
      if (conditionData.operator === "between" || conditionData.operator === "notBetween")
        return (
          conditionData.name &&
          conditionData.condition &&
          conditionData.operator &&
          conditionData.fromValue &&
          conditionData.toValue
        );
      else {
        return conditionData.name && conditionData.condition && conditionData.operator && conditionData.value;
      }

    case ConditionFact.PREV_PAGE_OFFER:
      if (activePage === PageType.THANK_YOU_PAGE || activePage === PageType.ORDER_STATUS_PAGE || activePage === PageType.POST_PURCHASE_PAGE1 || activePage === PageType.POST_PURCHASE_PAGE2) {
        return (conditionData.thirdOperatorOption && conditionData.thirdOperatorOption === "ONEOFSELECTED") ? (conditionData.items.length > 0 && conditionData.name) : conditionData.name
      } else {
        return conditionData.name
      }

    default:
      return false;
  }
};

const getConditionsParams = async (platformStoreId: string, activePage: PageType, conditionData: any) => {
  const baseData = {
    platformStoreId,
    location: activePage,
    name: conditionData.name,
  };

  const baseRule = {
    all: [
      {
        fact: conditionData.fact,
        operator: getOperatorValue(conditionData),
        extraFields: {
          condition: conditionData.condition,
          operatorValue: conditionData.operator,
        },
      },
    ],
  };

  switch (conditionData?.fact) {
    case ConditionFact.SPECIFIC_PRODUCT:
      return {
        ...baseData,
        rule: {
          ...baseRule,
          all: [
            {
              ...baseRule.all[0],
              value: conditionData.variantIds,
              extraFields: {
                ...baseRule.all[0].extraFields,
                productIds: conditionData.productIds,
              },
            },
          ],
        },
      };
    case ConditionFact.PRODUCT_COLLECTION:
      return {
        ...baseData,
        rule: {
          ...baseRule,
          all: [
            {
              ...baseRule.all[0],
              value: conditionData.productCollectionIds?.map((a: any) => a.id),
              extraFields: {
                ...baseRule.all[0].extraFields,
                productCollections: conditionData.productCollectionIds,
              },
            },
          ],
        },
      };
    case ConditionFact.PRODUCT_TAGS:
    case ConditionFact.PRODUCT_SALES_CHANNEL:
    case ConditionFact.CUSTOMER_COUNTRY:
    case ConditionFact.CUSTOMER_LANGUAGE:
      return {
        ...baseData,
        rule: {
          ...baseRule,
          all: [
            {
              ...baseRule.all[0],
              value: conditionData?.items,
              extraFields: {
                ...baseRule.all[0].extraFields,
                itemsInfo: conditionData?.itemsInfo,
              },
            },
          ],
        },
      };
    case ConditionFact.PRODUCT_TITLE:
    case ConditionFact.PRODUCT_VARIANT_NAME:
    case ConditionFact.PRODUCT_SKU:
      return {
        ...baseData,
        rule: {
          ...baseRule,
          all: [
            {
              ...baseRule.all[0],
              value: conditionData.value,
            },
          ],
        },
      };

    case ConditionFact.CUSTOMER_EMAIL:
    case ConditionFact.DISCOUNT_CODE:
      const {
        fact,
        operator,
        extraFields: { operatorValue },
      } = baseRule.all[0];

      return {
        ...baseData,
        rule: {
          ...baseRule,
          all: [
            {
              fact,
              operator,
              extraFields: { operatorValue },
              value: conditionData.value,
            },
          ],
        },
      };
    case ConditionFact.CUSTOMER_NUMBER_OF_ORDERS:
    case ConditionFact.CART_OR_ORDER_VALUE:
      return {
        ...baseData,
        rule: {
          ...baseRule,
          all: [
            {
              ...baseRule.all[0],
              value:
                conditionData.operator === "between" || conditionData.operator === "notBetween"
                  ? [+conditionData?.fromValue, +conditionData?.toValue]
                  : +conditionData.value,
              extraFields: {
                ...baseRule.all[0].extraFields,
                fromValue: conditionData?.fromValue,
                toValue: conditionData?.toValue,
              },
            },
          ],
        },
      };

    case ConditionFact.PREV_PAGE_OFFER:
      const value = await previousOfferValues(conditionData?.condition, conditionData?.operator, baseData.location, conditionData?.items ?? [], conditionData.thirdOperatorOption ?? "");

      return {
        ...baseData,
        rule: {
          ...baseRule,
          all: [
            {
              ...baseRule.all[0],
              extraFields: {
                ...baseRule.all[0].extraFields,
                ...(baseData.location === PageType?.THANK_YOU_PAGE || baseData.location === PageType?.ORDER_STATUS_PAGE || baseData.location === PageType.POST_PURCHASE_PAGE1 || baseData.location === PageType.POST_PURCHASE_PAGE2 ? { conditionIds: {key: conditionData.thirdOperatorOption || "ANY", value: (conditionData.thirdOperatorOption || "ANY") === "ANY" ? [] : conditionData?.items ?? []} } : {})
              },
              value
            },
          ],
        },
      };

    case ConditionFact.CUSTOMER_SEGMENTS:
      return {
        ...baseData,
        rule: {
          ...baseRule,
          all: [
            {
              ...baseRule.all[0],
              value: conditionData?.items,
              extraFields: {
                ...baseRule.all[0].extraFields,
                itemsInfo: conditionData?.itemsInfo,
              },
            },
          ],
        },
        metafields : {
          segments: conditionData?.items
        }
      };

    default:
      return false;
  }
};

const previousOfferValues = async (condition: string, operator: string, activePage: string, items: string[], thirdOperatorOption: string) => {
  const getValue = (operator: string) => {
    switch (operator) {
      case PageType.CHECKOUT:
        return [`${PageType.CHECKOUT}${valuePrefix}`];
      case PageType.POST_PURCHASE_PAGE1:
        return [`${PageType.POST_PURCHASE_PAGE1}${valuePrefix}`];
      case PageType.POST_PURCHASE_PAGE2:
        return [`${PageType.POST_PURCHASE_PAGE2}${valuePrefix}`];
      case PageType.THANK_YOU_PAGE:
        return [`${PageType.THANK_YOU_PAGE}${valuePrefix}`];
      case PageType.ORDER_STATUS_PAGE:
        return [`${PageType.ORDER_STATUS_PAGE}${valuePrefix}`];
      case `${PageType.POST_PURCHASE_PAGE1}-${PageType.POST_PURCHASE_PAGE2}`:
        return [`${PageType.POST_PURCHASE_PAGE1}${valuePrefix}`, `${PageType.POST_PURCHASE_PAGE2}${valuePrefix}`];
      case `${PageType.POST_PURCHASE_PAGE1}&${PageType.POST_PURCHASE_PAGE2}`:
        return [`${PageType.POST_PURCHASE_PAGE1}${valuePrefix}`, `${PageType.POST_PURCHASE_PAGE2}${valuePrefix}`];
      default:
        return [];
    }
  }
  const valuePrefix = condition === "accepted" ? `|ACCEPTED` : `|DECLINED`;

  if (activePage === PageType.THANK_YOU_PAGE || activePage === PageType.ORDER_STATUS_PAGE || activePage === PageType.POST_PURCHASE_PAGE1 || activePage === PageType.POST_PURCHASE_PAGE2) {
    if (thirdOperatorOption !== "ONEOFSELECTED") {
      const { data: allPageConditions } = await getAllPageConditions();
      const value = getValue(operator);
      const mappedData = value?.map(_ => allPageConditions.map((data: any) => (`${_}|${data.id}`)))?.flat();

      return mappedData;
    } else {
      if (items?.length > 0) {
        const value = getValue(operator);
        const mappedData = value?.map(_ => items.map((id: string) => (`${_}|${id}`)))?.flat();

        return mappedData;
      }
    }
  } else {
    return getValue(operator)
  }
};

const getOperatorValue = (conditionData: any) => {
  switch (conditionData?.fact) {
    case ConditionFact.SPECIFIC_PRODUCT:
      switch (`${conditionData.condition}-${conditionData.operator}`) {
        case "atLeastOneProduct-is":
          return "containsAtLeastOne";
        case "atLeastOneProduct-isNot":
          return "notContainsAtLeastOne";
        case "entireOrder-is":
          return "containsAll";
        case "entireOrder-isNot":
          return "notContainsAll";
        default:
          return "";
      }
    case ConditionFact.PRODUCT_TAGS:
      switch (`${conditionData.condition}-${conditionData.operator}`) {
        case "atLeastOneProduct-hasAtLeastOne":
          return "containsAtLeastOneWithAtLeastOneTag";
        case "atLeastOneProduct-hasAll":
          return "containsAtLeastOneWithAllTags";
        case "atLeastOneProduct-notHaveAnyOne":
          return "containsAtLeastOneWithoutAnyTag";
        case "atLeastOneProduct-notHaveAll":
          return "containsAtLeastOneWithoutAllTags";
        case "entireOrder-hasAtLeastOne":
          return "containsAllWithAtLeastOneTag";
        case "entireOrder-hasAll":
          return "containsAllWithAllTags";
        case "entireOrder-notHaveAnyOne":
          return "containsAllWithoutAnyTag";
        case "entireOrder-notHaveAll":
          return "containsAllWithoutAllTags";
        default:
          return "";
      }
    case ConditionFact.PRODUCT_COLLECTION:
      switch (`${conditionData.condition}-${conditionData.operator}`) {
        case "atLeastOneProduct-is":
          return "containsAtLeastOneWithAtLeastOne";
        case "atLeastOneProduct-isNot":
          return "containsAtLeastOneWithoutAll";
        case "entireOrder-is":
          return "containsAllWithAtLeastOne";
        case "entireOrder-isNot":
          return "containsAllWithoutAll";
        default:
          return "";
      }
    case ConditionFact.PRODUCT_TITLE:
    case ConditionFact.PRODUCT_VARIANT_NAME:
    case ConditionFact.PRODUCT_SKU:
      switch (`${conditionData.condition}-${conditionData.operator}`) {
        case "atLeastOneProduct-isExactSame":
          return "containsAtLeastOneAndEqual";
        case "atLeastOneProduct-isNotExactSame":
          return "containsAtLeastOneAndNotEqual";
        case "atLeastOneProduct-startWith":
          return "containsAtLeastOneAndStartsWith";
        case "atLeastOneProduct-notStartWith":
          return "containsAtLeastOneAndNotStartsWith";
        case "atLeastOneProduct-endWith":
          return "containsAtLeastOneAndEndsWith";
        case "atLeastOneProduct-notEndWith":
          return "containsAtLeastOneAndNotEndsWith";
        case "atLeastOneProduct-contains":
          return "containsAtLeastOneAndContains";
        case "atLeastOneProduct-notContains":
          return "containsAtLeastOneAndNotContains";
        case "onlyProduct-isExactSame":
          return "containsAllAndEqual";
        case "onlyProduct-isNotExactSame":
          return "containsAllAndNotEqual";
        case "entireOrder-startWith":
          return "containsAllAndStartsWith";
        case "entireOrder-notStartWith":
          return "containsAllAndNotStartsWith";
        case "entireOrder-endWith":
          return "containsAllAndEndsWith";
        case "entireOrder-notEndWith":
          return "containsAllAndNotEndsWith";
        case "entireOrder-contains":
          return "containsAllAndContains";
        case "entireOrder-notContains":
          return "containsAllAndNotContains";

        default:
          return "";
      }

    case ConditionFact.CUSTOMER_EMAIL:
    case ConditionFact.DISCOUNT_CODE:
      switch (`${conditionData.operator}`) {
        case "isExactSame":
          return "containsAtLeastOneAndEqual";
        case "isNotExactSame":
          return "containsAtLeastOneAndNotEqual";
        case "startWith":
          return "containsAtLeastOneAndStartsWith";
        case "notStartWith":
          return "containsAtLeastOneAndNotStartsWith";
        case "endWith":
          return "containsAtLeastOneAndEndsWith";
        case "notEndWith":
          return "containsAtLeastOneAndNotEndsWith";
        case "contains":
          return "containsAtLeastOneAndContains";
        case "notContains":
          return "containsAtLeastOneAndNotContains";

        default:
          return "";
      }

    case ConditionFact.CUSTOMER_NUMBER_OF_ORDERS:
    case ConditionFact.CART_OR_ORDER_VALUE:
      switch (`${conditionData.operator}`) {
        case "eql":
          return "equal";
        case "notEql":
          return "notEqual";
        case "grtThan":
          return "greaterThan";
        case "grtOrEql":
          return "greaterThanInclusive";
        case "ls":
          return "lessThan";
        case "lsOrEql":
          return "lessThanInclusive";
        case "between":
          return "between";
        case "notBetween":
          return "notBetween";

        default:
          return "";
      }

    case ConditionFact.PRODUCT_SALES_CHANNEL:
      switch (`${conditionData.condition}-${conditionData.operator}`) {
        case "atLeastOneProduct-isAvailableAtOneOfTheSalesChannelsFromTheList":
          return "containsAtLeastOne";

        case "atLeastOneProduct-isAvailableAtAllTheSalesChannelsFromTheList":
          return "containsAtLeastOneHavingAll";

        case "atLeastOneProduct-isNotAvailableAtOneOfTheSalesChannelsFromTheList":
          return "notContainsAtLeastOne";

        case "atLeastOneProduct-isNotAvailableAtAllTheSalesChannelsFromTheList":
          return "containsAtLeastOneNotHavingAll";

        case "allProducts-areAvailableAtOneOfTheSalesChannelsFromTheList":
          return "containsAllHavingAtLeastOne";

        case "allProducts-areAvailableAtAllTheSalesChannelsFromTheList":
          return "containsAll";

        case "allProducts-areNotAvailableAtOneOfTheSalesChannelsFromTheList":
          return "containsAllNotHavingAny";

        case "allProducts-areNotAvailableAtAllTheSalesChannelsFromTheList":
          return "notContainsAll";

        default:
          break;
      }

      break;

    case ConditionFact.CUSTOMER_LANGUAGE:
    case ConditionFact.CUSTOMER_COUNTRY:
    case ConditionFact.CUSTOMER_SEGMENTS:
      switch (`${conditionData.operator}`) {
        case "containsAtLeastOne":
          return "containsAtLeastOne";

        case "notContainsAtLeastOne":
          return "notContainsAtLeastOne";

        default:
          break;
      }

      break;

    case ConditionFact.PREV_PAGE_OFFER:
      switch (`${conditionData.operator}`) {
        case `${PageType.POST_PURCHASE_PAGE1}&${PageType.POST_PURCHASE_PAGE2}`:
          return 'containsAll'

        default:
          return "containsAtLeastOne";
      }

    case ConditionFact.CUSTOMER_TAG:
      switch(`${conditionData.operator}`) {
        case "hasAtLeastOne":
          return "containsAtLeastOneWithAtLeastOneTag";
        case "hasAll":
          return "containsAtLeastOneWithAllTags";
        case "notHaveAnyOne":
          return "containsAtLeastOneWithoutAnyTag";
        case "notHaveAll":
          return "containsAtLeastOneWithoutAllTags";
        default:
          break;
      }
  }

  return "";
};

const getConditionObject = (condition: any) => {
  const baseObject = {
    name: condition.name,
    fact: condition?.rule?.all[0]?.fact,
    condition: condition?.rule?.all[0]?.extraFields?.condition,
    operator: condition?.rule?.all[0]?.extraFields?.operatorValue,
  };

  switch (condition?.rule?.all[0]?.fact) {
    case ConditionFact.SPECIFIC_PRODUCT:
      return {
        ...baseObject,
        productIds: condition?.rule?.all[0]?.extraFields?.productIds || [],
        variantIds: condition?.rule?.all[0]?.value || [],
      };
    case ConditionFact.PRODUCT_COLLECTION:
      return {
        ...baseObject,
        productCollectionIds: condition?.rule?.all[0]?.extraFields?.productCollections || [],
      };
    case ConditionFact.PRODUCT_TAGS:
    case ConditionFact.CUSTOMER_COUNTRY:
    case ConditionFact.CUSTOMER_LANGUAGE:
    case ConditionFact.PRODUCT_SALES_CHANNEL:
    case ConditionFact.CUSTOMER_TAG:
    case ConditionFact.CUSTOMER_SEGMENTS:
      return {
        ...baseObject,
        items: condition?.rule?.all[0]?.value || [],
        itemsInfo: condition?.rule?.all[0]?.extraFields?.itemsInfo,
      };
    case ConditionFact.CUSTOMER_EMAIL:
    case ConditionFact.PRODUCT_TITLE:
    case ConditionFact.DISCOUNT_CODE:
    case ConditionFact.PRODUCT_VARIANT_NAME:
    case ConditionFact.PRODUCT_SKU:
      return {
        ...baseObject,
        value: condition?.rule?.all[0]?.value || "",
      };

    case ConditionFact.CUSTOMER_NUMBER_OF_ORDERS:
    case ConditionFact.CART_OR_ORDER_VALUE:
      const { operatorValue } = condition?.rule?.all[0]?.extraFields || {};

      if (operatorValue === "between" || operatorValue === "notBetween") {
        return {
          ...baseObject,
          fromValue: condition?.rule?.all[0]?.extraFields?.fromValue || 0,
          toValue: condition?.rule?.all[0]?.extraFields?.toValue || 0,
        };
      } else {
        return {
          ...baseObject,

          value: condition?.rule?.all[0]?.value || 0,
        };
      }

    case ConditionFact.PREV_PAGE_OFFER:
      if (condition?.rule?.all[0]?.extraFields?.conditionIds?.key) {
        return { ...baseObject, thirdOperatorOption: condition?.rule?.all[0]?.extraFields?.conditionIds?.key, items: condition?.rule?.all[0]?.extraFields?.conditionIds?.value};
      } else {
        return { ...baseObject };
      }

    default:
      return baseObject;
  }
};

const compareWithOldCondition = (oldCondition: any, condition: any, activePage: string | undefined) => {
  const baseComparison = () =>
    oldCondition.name !== condition.name ||
    oldCondition.condition !== condition.condition ||
    oldCondition.operator !== condition.operator;

  switch (condition?.fact) {
    case ConditionFact.SPECIFIC_PRODUCT:
      if (condition?.productIds?.length <= 0) {
        return false;
      }

      return (
        baseComparison() ||
        oldCondition?.productIds?.length !== condition?.productIds?.length ||
        condition?.productIds?.some((productId: any) => !oldCondition?.productIds?.includes(productId)) ||
        condition?.variantIds?.some((variantId: any) => !oldCondition?.variantIds?.includes(variantId))
      );
    case ConditionFact.PRODUCT_COLLECTION:
      if (condition?.productCollectionIds?.length <= 0) {
        return false;
      }

      return (
        baseComparison() ||
        oldCondition?.productCollectionIds?.length !== condition?.productCollectionIds?.length ||
        condition?.productCollectionIds?.some(
          (variantId: any) => !oldCondition?.productCollectionIds?.includes(variantId),
        )
      );
    case ConditionFact.PRODUCT_TAGS:
    case ConditionFact.CUSTOMER_COUNTRY:
    case ConditionFact.CUSTOMER_LANGUAGE:
    case ConditionFact.PRODUCT_SALES_CHANNEL:
    case ConditionFact.CUSTOMER_TAG:
    case ConditionFact.CUSTOMER_SEGMENTS:
      if (condition?.items?.length <= 0) {
        return false;
      }

      return (
        baseComparison() ||
        oldCondition?.items?.length !== condition?.items?.length ||
        condition?.items?.some((itemId: any) => !oldCondition?.items?.includes(itemId))
      );
    case ConditionFact.CUSTOMER_EMAIL:
    case ConditionFact.PRODUCT_TITLE:
    case ConditionFact.DISCOUNT_CODE:
    case ConditionFact.PRODUCT_VARIANT_NAME:
    case ConditionFact.PRODUCT_SKU:
      if (!condition?.value) {
        return false;
      }

      return baseComparison() || oldCondition?.value !== condition?.value;
    case ConditionFact.CUSTOMER_NUMBER_OF_ORDERS:
    case ConditionFact.CART_OR_ORDER_VALUE:
      if (condition.operator === "between" || condition.operator === "notBetween") {
        return (
          baseComparison() ||
          oldCondition?.fromValue !== condition?.fromValue ||
          oldCondition?.toValue !== condition?.toValue
        );
      } else {
        return baseComparison() || oldCondition?.value !== condition?.value;
      }

    case ConditionFact.PREV_PAGE_OFFER:
      let canSave = baseComparison();
      const isInPage = activePage === PageType.THANK_YOU_PAGE || activePage === PageType.ORDER_STATUS_PAGE || activePage === PageType.POST_PURCHASE_PAGE1 || activePage === PageType.POST_PURCHASE_PAGE2;

      if (isInPage && condition.thirdOperatorOption !== oldCondition.thirdOperatorOption) {
        canSave = true
      }

      if (isInPage && condition.thirdOperatorOption === "ONEOFSELECTED" && !condition.items?.length && canSave) {
        canSave = false
      } else if (isInPage && condition.thirdOperatorOption === "ONEOFSELECTED" && (oldCondition?.items?.length !== condition?.items?.length ||
        condition?.items?.some((itemId: any) => !oldCondition?.items?.includes(itemId))) && !canSave) {
        canSave = true
      }

      return canSave

    default:
      return true;
  }
};

export { validateRequiredValue, canSaveCondition, getConditionsParams, getConditionObject, compareWithOldCondition };
