import { useToast } from "@reconvert/react-ui-component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../app/hooks";
import { selectAppState } from "../app/slice/appSlice";
import { selectConditionsState } from "../app/slice/conditionSlice";
import useConditions from "./useConditions";
import { Condition } from "../models";
import {
  canSaveCondition,
  compareWithOldCondition,
  getConditionObject,
  getConditionsParams,
  validateRequiredValue,
} from "../utils/validateConditionData";
import { getConditionsMetaData } from "../utils/conditionsMetaData";

interface Option {
  value: string;
  label: string;
}

interface OperatorOption {
  value: string;
  label: string;
}

interface ConditionType {
  value: string;
  options: Option[];
  operatorOptions: OperatorOption[];
  thirdOperatorOptions?: OperatorOption[];
}

interface ConditionsMetaDataType {
  [key: string]: ConditionType;
}

export interface ConditonData {
  name: string;
  fact: string;
  condition: string;
  operator: string;
  productIds?: string[];
  variantIds?: string[];
  value?: string;
  fromValue?: string;
  toValue?: string;
  items?: string | string[];
  itemsInfo?: string | string[];
  productCollectionIds?: { id: string; name: string }[];
  thirdOperatorOption?: string;
}

interface EditConditionReturnType {
  conditionData: ConditonData;
  updateConditionData: ({
    name,
    fact,
    condition,
    operator,
    productIds,
    variantIds,
    value,
    fromValue,
    toValue,
    productCollectionIds,
    items,
    itemsInfo,
    thirdOperatorOption
  }: {
    name?: string;
    fact?: string;
    condition?: string;
    operator?: string;
    productIds?: string[];
    variantIds?: string[];
    value?: string;
    fromValue?: string;
    toValue?: string;
    items?: string | string[];
    itemsInfo?: string | string[];
    productCollectionIds?: { id: string; name: string }[];
    thirdOperatorOption?: string;
  }) => void;
  handleOnSubmit: () => void;
  canSave: boolean;
  conditionsMetaData: ConditionsMetaDataType;
}

const initialConditonData: ConditonData = {
  name: "My new Condition",
  fact: "SPECIFIC_PRODUCT",
  condition: "atLeastOneProduct",
  operator: "is",
  productIds: [],
  variantIds: [],
};

const useEditCondition = (): EditConditionReturnType => {
  const { platformStoreId, activePage, storeInfo } = useAppSelector(selectAppState);
  const { conditions } = useAppSelector(selectConditionsState);
  const { t } = useTranslation();
  const { error } = useToast();
  const { selectedCondition, handleUpdateCondition, handleCreateCondition } = useConditions();
  const [conditionData, setConditionData] = useState<ConditonData>(initialConditonData);

  const conditionsMetaData = useMemo(
    () => getConditionsMetaData(activePage!, storeInfo?.isShopifyPlusMerchant!, conditionData.condition),
    [activePage, storeInfo, conditionData.condition],
  );

  const getPlainConditionObject = useCallback((condition: Condition) => {
    return getConditionObject(condition);
  }, []);

  function getNextSequenceNumber(items: string[]) {
    const conditionItems = items.filter(item => /My new Condition \(\d+\)/.test(item) || /My new Condition/.test(item));

    const sequenceNumbers = conditionItems.map(item => {
      if (item === "My new Condition") {
        return 1;
      }

      const match = item.match(/My new Condition \((\d+)\)/);

      return match ? parseInt(match[1]) + 1 : 0;
    });

    const nextSequenceNumber = sequenceNumbers.length > 0 ? Math.max(...sequenceNumbers) + 1 : 0;

    return nextSequenceNumber;
  }

  useEffect(() => {
    if (selectedCondition) {
      setConditionData(getPlainConditionObject(selectedCondition));
    } else {
      const number = getNextSequenceNumber(conditions.map(a => a.name));

      if (number > 1) {
        const newItem = `My new Condition (${number - 1})`;

        setConditionData({
          ...conditionData,
          name: newItem,
        });
      }
    }
  }, [selectedCondition, getPlainConditionObject, conditions]);

  const updateConditionData = useCallback(
    ({
      name,
      fact,
      condition,
      operator,
      productIds,
      variantIds,
      value,
      fromValue,
      toValue,
      items,
      itemsInfo,
      productCollectionIds,
      thirdOperatorOption
    }: {
      name?: string;
      fact?: string;
      condition?: string;
      operator?: string;
      productIds?: string[];
      variantIds?: string[];
      value?: string;
      fromValue?: string;
      toValue?: string;
      items?: string | string[];
      itemsInfo?: string | string[];
      productCollectionIds?: { id: string; name: string }[];
      thirdOperatorOption?: string;
    }) => {
      setConditionData({
        name: name !== undefined ? name : conditionData.name,
        fact: fact || conditionData.fact,
        condition: condition || conditionData.condition,
        operator: operator || conditionData.operator,
        productIds: productIds || conditionData.productIds,
        variantIds: variantIds || conditionData.variantIds,
        items: items || conditionData.items,
        itemsInfo: itemsInfo || conditionData.itemsInfo,
        value: value !== undefined ? value : conditionData?.value,
        fromValue: fromValue !== undefined ? fromValue : conditionData?.fromValue,
        toValue: toValue !== undefined ? toValue : conditionData?.toValue,
        productCollectionIds: productCollectionIds || conditionData.productCollectionIds,
        thirdOperatorOption: thirdOperatorOption || conditionData.thirdOperatorOption
      });
    },
    [conditionData],
  );

  const highestPriority = useMemo(() => {
    return conditions?.reduce((maxPriority, condition) => {
      const priority = condition.priority || 0;

      return Math.max(maxPriority, priority);
    }, Number.NEGATIVE_INFINITY);
  }, [conditions]);

  const handleOnSubmit = useCallback(async () => {
    const { isInValid, isInputvalid } = validateRequiredValue(conditionData);

    if (!isInputvalid) {
      return error(t("Please enter valid value"));
    }

    if (isInValid) {
      return error(t("Please fill all required field"));
    }

    const params = await getConditionsParams(platformStoreId, activePage!, conditionData);

    if (selectedCondition) {
      handleUpdateCondition(selectedCondition.id, params);
    } else {
      handleCreateCondition({ ...params, priority: highestPriority + 1, name: conditionData.name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionData, platformStoreId, activePage, selectedCondition, highestPriority]);

  const canSave = useMemo(() => {
    if (!selectedCondition) {
      // Creating condition
      return canSaveCondition(conditionData, activePage);
    }

    // Editing condition
    const oldCondition = getPlainConditionObject(selectedCondition);

    return compareWithOldCondition(oldCondition, conditionData, activePage);
  }, [selectedCondition, conditionData, getPlainConditionObject, activePage]);

  return {
    conditionData,
    updateConditionData,
    handleOnSubmit,
    canSave,
    conditionsMetaData,
  };
};

export default useEditCondition;
