import { ConditionFact, PageType } from "@reconvert/reconvert-utils";
import { APP_PLATFORM } from "../config";
import { Platform } from "../models";

type ConditionMetaData = Partial<{
  [key in ConditionFact]: {
    value: string;
    options: { value: string; label: string; }[];
    operatorOptions: { value: string; label: string; }[];
    thirdOperatorOptions?: { value: string; label: string; }[];
  };
}>;

const getConditionsMetaData = (activePage: PageType, isShopifyPlusMerchant: boolean, conditionValue: string) : ConditionMetaData => {
  return {
    [ConditionFact.SPECIFIC_PRODUCT]: {
      value: "SPECIFIC_PRODUCT",
      options: [
        {
          value: "atLeastOneProduct",
          label: "At least one product in the cart",
        },
        {
          value: "entireOrder",
          label: "Every product in the cart",
        },
      ],
      operatorOptions: [
        {
          value: "is",
          label: "Is from the list",
        },
        {
          value: "isNot",
          label: "Is not from the list",
        },
      ],
    },
    [ConditionFact.PRODUCT_COLLECTION]: {
      value: "PRODUCT_COLLECTION",
      options: [
        {
          value: "atLeastOneProduct",
          label: "At least one product in the cart",
        },
        {
          value: "entireOrder",
          label: "Every products in the cart",
        },
      ],
      operatorOptions: [
        {
          value: "is",
          label:
            APP_PLATFORM === Platform.Shopify ? "Is in a collection from the list" : "Is in a category from the list",
        },
        {
          value: "isNot",
          label:
            APP_PLATFORM === Platform.Shopify
              ? "Is not in a collection from the list"
              : "Is not in a category from the list",
        },
      ],
    },
    [ConditionFact.PRODUCT_TAGS]: {
      value: "PRODUCT_TAGS",
      options: [
        {
          value: "atLeastOneProduct",
          label: "At least one product in the cart",
        },
        {
          value: "entireOrder",
          label: "Every products in the cart",
        },
      ],
      operatorOptions: [
        {
          value: "hasAtLeastOne",
          label: "Has at least one of the tags from the list",
        },
        {
          value: "hasAll",
          label: "Has all the tags from the list",
        },
        {
          value: "notHaveAnyOne",
          label: "Doesn't have some of the tags from the list",
        },
        {
          value: "notHaveAll",
          label: "Doesn't have all the tags from the list",
        },
      ],
    },
    [ConditionFact.PRODUCT_TITLE]: {
      value: "PRODUCT_TITLE",
      options: [
        {
          value: "atLeastOneProduct",
          label: "The title of at least one product in the cart",
        },
        {
          value: "entireOrder",
          label: "The title of all products in the cart",
        },
        {
          value: "onlyProduct",
          label: "The only product title in the cart",
        },
      ],
      operatorOptions: getOperatorOptions(ConditionFact.PRODUCT_TITLE, conditionValue, "title"),
    },
    [ConditionFact.DISCOUNT_CODE]: {
      value: "DISCOUNT_CODE",
      options: [],
      operatorOptions: [
        {
          value: "isExactSame",
          label: "Is (exact code)",
        },
        {
          value: "isNotExactSame",
          label: "Is not (exact code)",
        },
        {
          value: "startWith",
          label: "Starts with",
        },
        {
          value: "notStartWith",
          label: "Doesn't start with",
        },
        {
          value: "endWith",
          label: "Ends with",
        },
        {
          value: "notEndWith",
          label: "Doesn't end with",
        },
        {
          value: "contains",
          label: "Contains",
        },
        {
          value: "notContains",
          label: "Doesn't contain",
        },
      ],
    },
    [ConditionFact.PRODUCT_SKU]: {
      value: "PRODUCT_SKU",
      options: [
        {
          value: "atLeastOneProduct",
          label: "The SKU of at least one product in the cart",
        },
        {
          value: "entireOrder",
          label: "The SKU of all products in the cart",
        },
        {
          value: "onlyProduct",
          label: "The only SKU in the cart",
        },
      ],
      operatorOptions: getOperatorOptions(ConditionFact.PRODUCT_SKU, conditionValue, "SKU"),
    },
    [ConditionFact.CART_OR_ORDER_VALUE]: {
      value: "CART_OR_ORDER_VALUE",
      options: [],
      operatorOptions: [
        {
          value: "eql",
          label: "Equal to",
        },
        {
          value: "notEql",
          label: "Is not equal to",
        },
        {
          value: "grtThan",
          label: "Greater than",
        },
        {
          value: "grtOrEql",
          label: "Greater than or equals to",
        },
        {
          value: "ls",
          label: "Less than",
        },
        {
          value: "lsOrEql",
          label: "Less than or equals to",
        },
        {
          value: "between",
          label: "Between",
        },
        {
          value: "notBetween",
          label: "Is not between",
        },
      ],
    },
    [ConditionFact.PRODUCT_VARIANT_NAME]: {
      value: "PRODUCT_VARIANT_NAME",
      options: [
        {
          value: "atLeastOneProduct",
          label: "The variant of at least one product in the cart",
        },
        {
          value: "entireOrder",
          label: "The variant of all products in the cart",
        },
        {
          value: "onlyProduct",
          label: "The variant of only product in the cart",
        },
      ],
      operatorOptions: getOperatorOptions(ConditionFact.PRODUCT_VARIANT_NAME, conditionValue, "variant name"),
    },
    [ConditionFact.PRODUCT_SALES_CHANNEL]: {
      value: "PRODUCT_SALES_CHANNEL",
      options: [
        {
          value: "atLeastOneProduct",
          label: "At least one product in the cart",
        },
        {
          value: "entireOrder",
          label: "All products in the cart",
        },
      ],
      operatorOptions: getOperatorOptions(ConditionFact.PRODUCT_SALES_CHANNEL, conditionValue),
    },
    [ConditionFact.CUSTOMER_LANGUAGE]: {
      value: "CUSTOMER_LANGUAGE",
      options: [],
      operatorOptions: [
        {
          value: "containsAtLeastOne",
          label: "Is one of the languages from the list",
        },
        {
          value: "notContainsAtLeastOne",
          label: "Is not one of the languages from the list",
        },
      ],
    },
    [ConditionFact.CUSTOMER_COUNTRY]: {
      value: "CUSTOMER_COUNTRY",
      options: [],
      operatorOptions: [
        {
          value: "containsAtLeastOne",
          label: "Is one of the countries from the list",
        },
        {
          value: "notContainsAtLeastOne",
          label: "Is not one of the countries from the list",
        },
      ],
    },
    [ConditionFact.PREV_PAGE_OFFER]: {
      value: "PREV_PAGE_OFFER",
      options: getPrevPageOfferOptions(activePage),
      operatorOptions: getOperatorOptions(
        ConditionFact.PREV_PAGE_OFFER,
        conditionValue,
        "",
        activePage,
        isShopifyPlusMerchant,
      ),
      ...((activePage === PageType?.THANK_YOU_PAGE || activePage === PageType?.ORDER_STATUS_PAGE || activePage === PageType?.POST_PURCHASE_PAGE1 || activePage === PageType?.POST_PURCHASE_PAGE2) ? {
          thirdOperatorOptions: getThirdOperatorOptions(
            ConditionFact.PREV_PAGE_OFFER)
      } : {})
    },
    [ConditionFact.CUSTOMER_NUMBER_OF_ORDERS]: {
      value: "CUSTOMER_NUMBER_OF_ORDERS",
      options: [],
      operatorOptions: [
        {
          value: "eql",
          label: "Equal to",
        },
        {
          value: "notEql",
          label: "Is not equal to",
        },
        {
          value: "grtThan",
          label: "Greater than",
        },
        {
          value: "grtOrEql",
          label: "Greater than or equals to",
        },
        {
          value: "ls",
          label: "Less than",
        },
        {
          value: "lsOrEql",
          label: "Less than or equals to",
        },
        {
          value: "between",
          label: "Between",
        },
        {
          value: "notBetween",
          label: "Is not between",
        },
      ],
    },
    [ConditionFact.CUSTOMER_EMAIL]: {
      value: "CUSTOMER_EMAIL",
      options: [],
      operatorOptions: [
        {
          value: "isExactSame",
          label: "Is (exact email)",
        },
        {
          value: "isNotExactSame",
          label: "Is not (exact email)",
        },
        {
          value: "startWith",
          label: "Starts with",
        },
        {
          value: "notStartWith",
          label: "Doesn't start with",
        },
        {
          value: "endWith",
          label: "Ends with",
        },
        {
          value: "notEndWith",
          label: "Doesn't end with",
        },
        {
          value: "contains",
          label: "Contains",
        },
        {
          value: "notContains",
          label: "Doesn't contain",
        },
      ],
    },
    [ConditionFact.CUSTOMER_TAG]: {
      value: "CUSTOMER_TAG",
      options:[],
      operatorOptions: [
        {
          value: "hasAtLeastOne",
          label: "customer has at least one of the tags from the list ",
        },
        {
          value: "hasAll",
          label: "customer has all the tags from the list",
        },
        {
          value: "notHaveAnyOne",
          label: "customer doesn't have any of the tags from the list",
        },
        {
          value: "notHaveAll",
          label: "customer doesn't have all the tags from the list",
        },
      ],
    },
    [ConditionFact.CUSTOMER_SEGMENTS]: {
      value: "CUSTOMER_SEGMENTS",
      options:[],
      operatorOptions: [
        {
          value: "containsAtLeastOne",
          label: "Is one of the customer segments from the list",
        },
        {
          value: "notContainsAtLeastOne",
          label: "Is not one of the customer segments from the list",
        },
      ],
    },
  };
};

const getPrevPageOfferOptions = (activePage?: PageType) => {
  if (activePage === PageType?.POST_PURCHASE_PAGE1) {
    return [
      {
        value: "accepted",
        label: "An offer was accepted",
      },
    ];
  } else {
    return [
      {
        value: "accepted",
        label: "An offer was accepted",
      },
      // {
      //   value: "noOfferAccepted",
      //   label: "No offer was accepted",
      // },
      {
        value: "declined",
        label: "An offer was declined",
      },
    ];
  }
};

const getOperatorOptions = (
  conditionName: string,
  conditionValue: string,
  name?: string,
  activePage?: PageType,
  isShopifyPlusMerchant?: boolean,
) => {
  switch (conditionName) {
    case ConditionFact.PRODUCT_TITLE:
    case ConditionFact.PRODUCT_SKU:
    case ConditionFact.PRODUCT_VARIANT_NAME:
      const commonOptions = [
        {
          value: "startWith",
          label: "Starts with",
        },
        {
          value: "notStartWith",
          label: "Doesn't start with",
        },
        {
          value: "endWith",
          label: "Ends with",
        },
        {
          value: "notEndWith",
          label: "Doesn't end with",
        },
        {
          value: "contains",
          label: "Contains",
        },
        {
          value: "notContains",
          label: "Doesn't contain",
        },
      ];

      const exactSameOrNotOptions = [
        {
          value: "isExactSame",
          label: `Is (exact ${name})`,
        },
        {
          value: "isNotExactSame",
          label: `Is not (exact ${name})`,
        },
      ];

      switch (conditionValue) {
        case "atLeastOneProduct":
          return [...exactSameOrNotOptions, ...commonOptions];

        case "entireOrder":
          return [...commonOptions];

        case "onlyProduct":
          return [...exactSameOrNotOptions];

        default:
          return [];
      }

    case ConditionFact.PRODUCT_SALES_CHANNEL:
      switch (conditionValue) {
        case "atLeastOneProduct":
          return [
            {
              value: "isAvailableAtOneOfTheSalesChannelsFromTheList",
              label: "Is available at one of the sales channels from the list",
            },
            {
              value: "isAvailableAtAllTheSalesChannelsFromTheList",
              label: "Is available at all the sales channels from the list",
            },
            {
              value: "isNotAvailableAtOneOfTheSalesChannelsFromTheList",
              label: "Is not available at one of the sales channels from the list",
            },
            {
              value: "isNotAvailableAtAllTheSalesChannelsFromTheList",
              label: "Is not available at all the sales channels from the list",
            },
          ];

        case "entireOrder":
          return [
            {
              value: "areAvailableAtOneOfTheSalesChannelsFromTheList",
              label: "Are available at one of the sales channels from the list",
            },
            {
              value: "areAvailableAtAllTheSalesChannelsFromTheList",
              label: "Are available at all the sales channels from the list",
            },
            {
              value: "areNotAvailableAtOneOfTheSalesChannelsFromTheList",
              label: "Are not available at one of the sales channels from the list",
            },
            {
              value: "areNotAvailableAtAllTheSalesChannelsFromTheList",
              label: "Are not available at all the sales channels from the list",
            },
          ];
        default:
          return [];
      }

    case ConditionFact.PREV_PAGE_OFFER:
      const operators = [];

      if (isShopifyPlusMerchant && conditionValue === "accepted") {
        operators.push({
          value: PageType?.CHECKOUT,
          label: "On the checkout",
        });
      }

      if (
        activePage === PageType?.POST_PURCHASE_PAGE2 ||
        activePage === PageType?.THANK_YOU_PAGE ||
        activePage === PageType?.ORDER_STATUS_PAGE
      ) {
        operators.push({
          value: PageType?.POST_PURCHASE_PAGE1,
          label: "On the post purchase 1st page",
        });
      }

      if (activePage === PageType?.THANK_YOU_PAGE || activePage === PageType?.ORDER_STATUS_PAGE) {
        operators.push({
          value: PageType?.POST_PURCHASE_PAGE2,
          label: "On the post purchase 2nd page",
        });
        operators.push({
          value: `${PageType?.POST_PURCHASE_PAGE1}-${PageType?.POST_PURCHASE_PAGE2}`,
          label: "On one of the post purchase pages",
        });
        operators.push({
          value: `${PageType?.POST_PURCHASE_PAGE1}&${PageType?.POST_PURCHASE_PAGE2}`,
          label: "On all of the post purchase pages",
        });
      }

      if (activePage === PageType?.ORDER_STATUS_PAGE && conditionValue === "accepted") {
        operators.push({
          value: PageType?.THANK_YOU_PAGE,
          label: "On the thank you page",
        });
      }

      return operators;

    default:
      return [];
  }
};

const getThirdOperatorOptions = (conditionName: String) => {
  switch (conditionName) {
    case ConditionFact.PREV_PAGE_OFFER:

      return [{
        value: 'ANY',
        label: "On any condition",
      }, {
        value: 'ONEOFSELECTED',
        label: "On one of the selected condition"
      }];

    default:
      return []
  }
}

export { getConditionsMetaData };
