import { ANALYTIC_BASE_URL, BACKEND_BASE_URL } from "../config";

export const API_BASE_URL = BACKEND_BASE_URL;
export const API_ANALYTIC_URL = ANALYTIC_BASE_URL;

//API variables
export const AUTH_LOGIN = "/auth/login";
export const AUTH_TOKEN = "/auth/token";
export const GET_CONDITION = "/conditions";
export const GET_CONDITION_BY_ID = (id: string) => `/conditions/${id}`;
export const UPDATE_SLOT = (slotId: string) => `/slots/${slotId}`;
export const UPDATE_SLOT_CONFIGURATION = (slotConfigId: string) => `/slots/configuration/${slotConfigId}`;
export const SLOT = `/slots`;
export const SLOT_LOGIN = `/slots/login`;
export const CREATE_VARIANTS = "/variants";
export const UPDATE_VARIANT = "/variants";
export const UPDATE_VARIANT_BY_ID = (id: string) => `/variants/${id}`;
export const UPDATE_CONDITION = (id: string) => `/conditions/${id}`;
export const UPDATE_CONDITIONS = `/conditions`;
export const DELETE_VARIANTS_BY_ID = (variantId: string) => `/variants/${variantId}`;
export const DELETE_CONDITIONS_BY_ID = (variantId: string) => `/conditions/${variantId}`;
export const DELETE_SLOT = (slotNumber: number, location: string) => `/slots/${slotNumber}?location=${location}`;
export const GET_PRODUCT = `/store/products-search`;
export const GET_TAGS = `/store/tags`;
export const GET_PRODUCT_SALES_CHANNEL = `/store/sales-channels`;
export const GET_LANGUAGE = `/store/languages`;
export const GET_COUNTRIES = `/store/countries`;
export const GET_CUSTOMER_SEGEMENTS = `/store/segments`;
export const GET_PRODUCT_COLLECTION = `/store/collections/search`;
export const GET_VARIANT = (productIds: string) => `/store/variants/?productIds=${productIds}&limit=250`;
export const GET_ALL_VARIANT_ANALYTICS = (fromDate: string, toDate: string, conditionId: string, location: string) =>
  `/analytics/getAggregatedData?base=SLOT&location=${location}&conditionIds=${conditionId}&fromDate=${fromDate}&toDate=${toDate}`;
export const GET_VARIANTS_ANALYTICS = (fromDate: string, toDate: string, variantId: string, location: string) =>
  `/analytics/getAggregatedData?base=SLOT_VARIANT&location=${location}&variantIds=${variantId}&fromDate=${fromDate}&toDate=${toDate}&baseId=${variantId}`;
export const GET_WIDGET_ANALYTICS = (fromDate: string, toDate: string, variantId: string, location: string) =>
  `/analytics/getAggregatedData?base=WIDGET&location=${location}&variantIds=${variantId}&fromDate=${fromDate}&toDate=${toDate}`;
export const CHECKOUT_PROFILE = "/editor/checkout_profile";
export const GET_VARIANTS_LIST = `/variants`;
export const GET_ALL_VARIANTS = (conditionId: string, slotNo: number, excludeWidgets: boolean) =>
  `/variants/?conditionId=${conditionId}&slotNo=${slotNo}&excludeWidget=${excludeWidgets}`;
export type ApiError = { path?: string; message: string };
export type ErrorResponseBody = { success: false; errors: ApiError[] };
export type CreateOrReadResponseBody<T = any> = { success: true; data: T } | ErrorResponseBody;
export type UpdateOrDeleteResponseBody = { success: true; errors?: ApiError[] } | ErrorResponseBody;

//LAUNCH_DARKLY KEYS
export const LAUNCH_DARKLY_CHECKOUT_PAGE_KEY = "checkout-in-conditions-editor";
export const LAUNCH_DARKLY_POST_PURCHASE_PAGE_KEY = "post-purchase-in-conditions-editor";
export const LAUNCH_DARKLY_THANK_YOU_PAGE_KEY = "thank-you-page-in-conditions-editor";
export const LAUNCH_DARKLY_ORDER_STATUS_PAGE_KEY = "order-status-page-in-conditions-editor";
export const LAUNCH_DARKLY_PRE_PURCHASE_PAGE_KEY = "pre-purchase-in-conditions-editor";
export const LAUNCH_DARKLY_NEW_CONDITIONS = "new-conditions";
export const LAUNCH_DARKLY_PRODUCT_COLLECTION_CONDITION = "product-collection-condition";
export const SHOPIFY_SAVE_BAR_ON_FLOW_BUILDER = "shopify-save-bar-on-flow-builder";
export const LAUNCH_DARKLY_A_B_TESTING_SLOTS = "a-b-testing-for-slots";
export const LAUNCH_DARKLY_CUSTOMER_SEGMENTS = "customer-segment-condition";
export const LAUNCH_DARKLY_CUSTOMER_EMAIL_CONDITIONS = "customer-email-condition";
export const LAUNCH_DARKLY_CUSTOMER_NUMBER_OF_ORDERS_CONDITIONS = "customer-number-of-orders-condition";
export const LAUNCH_DARKLY_CUSTOMER_TAG_CONDITION = "customer-tag-condition"

export type LaunchDarklyFlag =
  | typeof LAUNCH_DARKLY_CHECKOUT_PAGE_KEY
  | typeof LAUNCH_DARKLY_POST_PURCHASE_PAGE_KEY
  | typeof LAUNCH_DARKLY_THANK_YOU_PAGE_KEY
  | typeof LAUNCH_DARKLY_ORDER_STATUS_PAGE_KEY
  | typeof LAUNCH_DARKLY_PRE_PURCHASE_PAGE_KEY
  | typeof LAUNCH_DARKLY_NEW_CONDITIONS
  | typeof LAUNCH_DARKLY_PRODUCT_COLLECTION_CONDITION
  | typeof LAUNCH_DARKLY_A_B_TESTING_SLOTS;
